import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";
import MediaCarousel from "../components/organisms/media-carousel";

function DesignQuoteCarousel({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Quote Carousel" />
      <h2>Quote Carousel</h2>
      <div className="design__stories">
        <DesignStory
          title="Quote Carousel"
          component={MediaCarousel}
          subComponent={MediaBlock}
          type="carousel"
          props={designProps}
        />
      </div>
    </div>
  );
}

DesignQuoteCarousel.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignQuoteCarousel.defaultProps = {
  props: {},
};

export default DesignQuoteCarousel;
